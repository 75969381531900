import axios from "axios";

export default {
  getReport(payload) {
    return axios.post("/landingD", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
      },
    });
  },
};
